/* eslint-disable import/no-cycle */
/* eslint-disable operator-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import AppContext from './AppContext';
import HomePage from './Pages/HomePage';
import JobPage from './Pages/JobPage';
import administration from './Assets/Images/administration.svg';
import buying from './Assets/Images/buying.svg';
import engineering from './Assets/Images/engineering.svg';
import finance from './Assets/Images/finance.svg';
import legal from './Assets/Images/legal.svg';
import peopleCulture from './Assets/Images/people-culture.svg';
import prCommunication from './Assets/Images/pr-communication.svg';
import product from './Assets/Images/product.svg';
import sustainability from './Assets/Images/sustainability.svg';
import brandPartnershipsIcon from './Assets/Images/handshake.svg';
import informationTechnology from './Assets/Images/network-wired-solid.svg';
import dataScientist from './Assets/Images/database-solid.svg';

const API_ENDPOINT =
  'https://lum4tnecm1.execute-api.ap-southeast-1.amazonaws.com/live/status/open/page/{page}';

const mGroupIcon = new Map();
mGroupIcon.set('Administration', administration);
mGroupIcon.set('Buying', buying);
mGroupIcon.set('Engineering', engineering);
mGroupIcon.set('Finance', finance);
mGroupIcon.set('People & Culture', peopleCulture);
mGroupIcon.set('PR & Communications', prCommunication);
mGroupIcon.set('Product Management', product);
mGroupIcon.set('Legal', legal);
mGroupIcon.set('Sustainability', sustainability);
mGroupIcon.set('Brand Partnerships', brandPartnershipsIcon);
mGroupIcon.set('Information Technology', informationTechnology);
mGroupIcon.set('Data', dataScientist);

const fetchJobs = async (page) => {
  const url = API_ENDPOINT.replace('{page}', page);

  const response = await fetch(url);
  const items = await response.json();

  return items;
};

const normalizeJobs = (items) => {
  const { groups, countries, types } = items.reduce(
    ({ groups, countries, types }, item) => {
      if (item.send_to_job_boards === 'Yes') {
        const groupItems = groups[item.department] || [];
        groups[item.department] = [...groupItems, item];
        countries[item.country_id] = true;
        types[item.type] = true;
      }

      return {
        groups,
        countries,
        types,
      };
    },
    { groups: {}, countries: {}, types: {} },
  );

  const nomalizedGroups = Object.keys(groups)
    .filter((key) => key !== '')
    .map((key) => ({
      icon: mGroupIcon.get(key),
      name: key,
      vacancies: groups[key].length,
      jobs: groups[key],
    }));

  return {
    groups: nomalizedGroups,
    countries: Object.keys(countries),
    types: Object.keys(types),
  };
};

const App = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [jobList, setJobList] = useState([]);
  const [types, setTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [jobs, setJobs] = useState([]);

  const fetchAllJobs = () => {
    // fetch data and update state
    const pages = [1, 2];
    Promise.all(pages.map((page) => fetchJobs(page)))
      .then((response) => {
        const allItems = [...response[0], ...response[1]];
        allItems.sort((a, b) => {
          if (a.title < b.title) {
            return -1;
          }
          if (a.title > b.title) {
            return 1;
          }
          return 0;
        });
        const { groups, types, countries } = normalizeJobs(allItems);
        setIsLoading(false);
        setJobList(groups);
        setTypes(types);
        setCountries(countries);
        setJobs(allItems);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(fetchAllJobs, []);

  return (
    <Router>
      <AppContext.Provider
        value={{
          isLoading,
          jobList,
          types,
          countries,
          jobs,
        }}
      >
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/job/:title/:boardCode" element={<JobPage />} />
        </Routes>
      </AppContext.Provider>
    </Router>
  );
};

export default App;
