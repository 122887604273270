import React from 'react';
import {
  HeaderNavigation,
  ALIGN,
  StyledNavigationList,
  StyledNavigationItem,
} from 'baseui/header-navigation';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { Link } from 'react-router-dom';

import logo from '../Assets/Images/LOGO-BLACK.svg';
import DrawerIcon from '../Components/DrawerIcon/index';

export default function TopNavigation() {
  const [css] = useStyletron();
  const menuItem = css({
    fontFamily: 'Barlow',
    fontSize: '17px',
    lineHeight: '20px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    textAlign: 'right',
    letterSpacing: '0.894737px',
    textTransform: 'uppercase',
    color: 'black',
    textDecoration: 'none',
  });

  return (
    <Grid>
      <Cell span={[0, 8, 12]}>
        <HeaderNavigation
          overrides={{
            Root: {
              style: {
                padding: '30px 0 30px 0 !important',
                border: 'none !important',
              },
            },
          }}
        >
          <StyledNavigationList $align={ALIGN.left}>
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
          </StyledNavigationList>
          <StyledNavigationList $align={ALIGN.center} />
          <div className="topnav">
            <style>
              {`
                  .topnavul > li {
                    padding: 0;
                    text-indent: 0;
                  }
                  .topnav ul li {
                    display: inline-block;
                    overflow: hidden;
                  }
                  .topnav ul li a {
                    margin: 0 10px;
                    font-size: 17px;
                    font-size: 1.0625rem;
                    line-height: 1.17647;
                    letter-spacing: 0.89px;
                    text-align: right;
                    text-transform: uppercase;
                    position: relative;
                    text-decoration: none;
                  }
                  .topnav ul li a:after {
                    content: '';
                    position: absolute;
                    top: 48%;
                    left: -5%;
                    height: 2px;
                    width: 0;
                    -webkit-transition: 0.5s ease-out;
                    -o-transition: 0.5s ease-out;
                    transition: 0.5s ease-out;
                    background-color: black;
                  }
                  .topnav ul li a:hover {
                    -webkit-transition: 0.3s;
                    -o-transition: 0.3s;
                    transition: 0.3s;
                    text-decoration: none;
                  }
                  .topnav ul li a:hover:after {
                    width: 110%;
                    -webkit-transition: 0.3s ease-in;
                    -o-transition: 0.3s ease-in;
                    transition: 0.3s ease-in;
                  }`}
            </style>
            <StyledNavigationList
              $align={ALIGN.right}
              className={css({ padding: '0 0 15px 0 !important' })}
            >
              <StyledNavigationItem>
                <a className={menuItem} href="https://global-fashion-group.com/about/">
                  ABOUT
                </a>
              </StyledNavigationItem>
              <StyledNavigationItem>
                <a className={menuItem} href="https://global-fashion-group.com/markets/">
                  MARKETS
                </a>
              </StyledNavigationItem>
              <StyledNavigationItem>
                <a className={menuItem} href="https://global-fashion-group.com/brands/">
                  BRANDS
                </a>
              </StyledNavigationItem>
              <StyledNavigationItem>
                <a className={menuItem} href="https://global-fashion-group.com/people/">
                  PEOPLE
                </a>
              </StyledNavigationItem>
              <StyledNavigationItem>
                <a className={menuItem} href="https://global-fashion-group.com/sustainability/">
                  SUSTAINABILITY
                </a>
              </StyledNavigationItem>
              <StyledNavigationItem>
                <a
                  className={menuItem}
                  href="https://ir.global-fashion-group.com/websites/globalfashion/English/1000/investor-relations.html"
                >
                  INVESTOR RELATIONS
                </a>
              </StyledNavigationItem>
              <StyledNavigationItem>
                <a className={menuItem} href="https://global-fashion-group.com/news/">
                  NEWS
                </a>
              </StyledNavigationItem>
              <StyledNavigationItem>
                <a className={menuItem} href="https://global-fashion-group.com/contact/">
                  CONTACT
                </a>
              </StyledNavigationItem>
            </StyledNavigationList>
          </div>
        </HeaderNavigation>
      </Cell>
      <Cell span={[4, 0, 0]}>
        <div className={css({ padding: '30px 0 30px 0', float: 'left' })}>
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
        <div
          className={css({
            padding: '30px 0 30px 0',
            position: 'absolute',
            right: '0',
          })}
        >
          <DrawerIcon />
        </div>
        <div className={css({ clear: 'both' })} />
      </Cell>
    </Grid>
  );
}
