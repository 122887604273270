/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { Select } from 'baseui/select';
import { Input, SIZE } from 'baseui/input';
import { useStyletron } from 'baseui';
import { Grid, Cell } from 'baseui/layout-grid';

const COUNTRY_OPTIONS = [
  { label: 'Australia', id: 'Australia' },
  { label: 'Germany', id: 'Germany' },
  { label: 'Malaysia', id: 'Malaysia' },
  { label: 'Singapore', id: 'Singapore' },
  { label: 'United Kingdom', id: 'United Kingdom' },
  { label: 'Viet Nam', id: 'Viet Nam' },
];

export default function Header({ types, jobFilter, onJobFilterChange }) {
  const [css] = useStyletron();

  const workTypeOptions = types.map((type) => ({ label: type, id: type }));
  const selectedCountry = COUNTRY_OPTIONS.find(
    (i) => i.id.toLowerCase() === jobFilter?.location.toLowerCase(),
  );
  const selectedWorkType = workTypeOptions.find(
    (i) => i.id.toLowerCase() === jobFilter?.type.toLowerCase(),
  );

  return (
    <Grid>
      <Cell span={[4, 8, 12]}>
        <div className={css({ padding: '30px 0', textAlign: 'center' })}>
          <Input
            clearable
            size={SIZE.compact}
            placeholder="Search Job"
            value={jobFilter.search}
            onChange={(e) => {
              onJobFilterChange({ search: e.target.value });
            }}
            overrides={{
              Root: {
                style: {
                  maxWidth: '250px',
                  height: '40px',
                  border: '1px black',
                  display: 'inline-block',
                  margin: '0 10px',
                  '@media screen and (max-width: 900px)': {
                    maxWidth: '100%',
                    margin: '0 0 10px 0',
                  },
                },
              },
              InputContainer: {
                style: {
                  border: '1px solid',
                  height: '40px',
                },
              },
              Input: {
                style: {
                  fontFamily: 'Barlow',
                  fontSize: '16px',
                  lineHeight: '19px',
                  fontStyle: 'normal',
                  fontWeight: 350,
                },
              },
            }}
          />
          <Select
            size={SIZE.compact}
            // options={props.countries.map((country) => ({ label: country, id: country }))}
            options={COUNTRY_OPTIONS}
            value={selectedCountry && [selectedCountry]}
            // placeholder="All Locations"
            onChange={(params) => {
              // eslint-disable-next-line react/prop-types
              const value = params.value[0] ? params.value[0].id : '';
              onJobFilterChange({ location: value });
            }}
            overrides={{
              Root: {
                style: {
                  maxWidth: '250px',
                  height: '40px',
                  border: '1px black',
                  display: 'inline-block',
                  margin: '0 10px',
                  '@media screen and (max-width: 900px)': {
                    maxWidth: '100%',
                    margin: '0 0 10px 0',
                  },
                },
              },
              ControlContainer: {
                style: {
                  border: '1px solid',
                  padding: '3px 0',
                },
              },
              ValueContainer: {
                style: {
                  fontFamily: 'Barlow',
                  fontSize: '16px',
                  lineHeight: '19px',
                  fontStyle: 'normal',
                  fontWeight: 350,
                },
              },
            }}
          />
          <Select
            size={SIZE.compact}
            value={selectedWorkType && [selectedWorkType]}
            placeholder="All Work Types"
            onChange={(params) => {
              // eslint-disable-next-line react/prop-types
              const value = params.value[0] ? params.value[0].id : '';
              onJobFilterChange({ type: value });
            }}
            options={workTypeOptions}
            overrides={{
              Root: {
                style: {
                  maxWidth: '250px',
                  height: '40px',
                  border: '1px black',
                  display: 'inline-block',
                  margin: '0 10px',
                  '@media screen and (max-width: 900px)': {
                    maxWidth: '100%',
                    margin: '0 0 10px 0',
                  },
                },
              },
              ControlContainer: {
                style: {
                  border: '1px solid',
                  padding: '3px 0',
                },
              },
              ValueContainer: {
                style: {
                  fontFamily: 'Barlow',
                  fontSize: '16px',
                  lineHeight: '19px',
                  fontStyle: 'normal',
                  fontWeight: 350,
                },
              },
            }}
          />
        </div>
      </Cell>
    </Grid>
  );
}
