/* eslint-disable react/prop-types */
import React from 'react';
import AccordionItem from './AccordionItem';
import './accordion.scss';
// import PropTypes from 'prop-types';

const Accordion = (props) => {
  // eslint-disable-next-line react/prop-types
  const { jobList } = props;

  return (
    <div {...{ className: 'wrapper' }}>
      <ul {...{ className: 'accordion-list' }}>
        {jobList.map((data, key) => (
          <li {...{ className: 'accordion-list__item', key }}>
            <AccordionItem
              title={data.name}
              groupIcon={data.icon}
              vacancies={data.jobs.length}
              jobs={data.jobs}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Accordion;
