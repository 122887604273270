import { createLightTheme } from 'baseui';

const boldFont = 'Barlow';

// https://github.com/uber/baseweb/blob/master/src/themes/shared/typography.js#L94
const fontTokens = {
  // h1
  font1050: {
    fontFamily: boldFont,
    fontSize: '48px',
    fontWeight: 500,
    lineHeight: '56px',
    textTransform: 'uppercase',
  },
  // h2
  font950: {
    fontFamily: boldFont,
    fontSize: '48px',
    fontWeight: 400,
    lineHeight: '56px',
    textTransform: 'uppercase',
  },
  // h3
  font850: {
    fontFamily: boldFont,
    fontSize: '36px',
    fontWeight: 400,
    lineHeight: '42px',
    textTransform: 'uppercase',
  },
  // h4,
  font750: {
    fontFamily: boldFont,
    fontSize: '28px',
    fontWeight: 400,
    lineHeight: '33px',
    textTransform: 'uppercase',
  },
  // h5,
  font650: {
    fontFamily: boldFont,
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '28px',
  },
  // h6
  font550: {
    fontFamily: boldFont,
    fontSize: '20px',
    lineHeight: '28px',
  },
};

const primitives = {
  primaryFontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
  ...fontTokens,
  colors: {
    white: '#fff',
    primary: '#1771B5',
    primary100: '#b3e2fa',
    primary200: '#81d0f7',
    primary300: '#52bdf2',
    primary400: '#32b0f0',
    primary500: '#1ea2ec',
    primary600: '#1c94dd',
    primary700: '#1a81c9',
    secondary: '#6b2c86',
    secondary100: '#e0c1e2',
    secondary200: '#cc98cf',
    secondary300: '#b771bb',
    secondary400: '#a754ad',
    secondary500: '#983c9f',
    secondary600: '#8b3798',
    secondary700: '#7a318f',
    black: '#000000',
    green600: '#219653',
    red600: '#D44333',
    gray50: '#f5f5f5',
    gray100: '#e9e9e9',
    gray200: '#d9d9d9',
    gray300: '#c4c4c4',
    gray400: '#9d9d9d',
    gray500: '#7b7b7b',
    gray600: '#555555',
    gray700: '#434343',
    gray800: '#262626',
    negative: '#862c2e',
    warning: '#b09e26',
    positive: '#7ec241',
    blue50: '#E2F4F9',
  },
  // https://github.com/uber/baseweb/blob/master/src/themes/shared/typography.js#L152
  typography: {
    HeadingXSmall: fontTokens.font550,
    HeadingSmall: fontTokens.font650,
    HeadingMedium: fontTokens.font750,
    HeadingLarge: fontTokens.font850,
    HeadingXLarge: fontTokens.font950,
    HeadingXXLarge: fontTokens.font1050,
  },
};

const overrides = {
  ...fontTokens,
  colors: {
    ...primitives.colors,
    // buttonPrimaryFill: primitives.colors.primary,
    // buttonPrimaryText: primitives.colors.white,
    // buttonPrimaryHover: primitives.colors.primary600,
    // buttonPrimaryActive: primitives.colors.primary700,
    // buttonPrimarySpinnerBackground: primitives.colors.primary,
    // // buttonSecondaryFill: primitives.colors.secondary,
    // // buttonSecondaryText: primitives.colors.white,
    // // buttonSecondaryHover: primitives.colors.secondary600,
    // // buttonSecondaryActive: primitives.colors.secondary700,
    // // buttonSecondarySpinnerBackground: primitives.colors.secondary,
    // // buttonTertiaryFill: primitives.colors.black,
    // buttonTertiaryText: primitives.colors.gray700,
    // buttonTertiaryHover: primitives.colors.gray700,
    // buttonTertiaryActive: primitives.colors.gray800,
    // buttonTertiarySpinnerBackground: primitives.colors.tertiary,
    inputBorder: '1px #000',
    inputFill: '#fff',
    inputFillActive: '#fff',
  },
  typography: {
    ...primitives.typography,
  },
};

export default createLightTheme(primitives, overrides);
