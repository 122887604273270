/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable object-curly-newline */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AccordionItem = (props) => {
  // Declare a new state variable, which we'll call "count"
  const [opened, setOpened] = useState(false);
  const { title, groupIcon, vacancies, jobs } = props;
  const history = useNavigate();

  return (
    <div
      {...{
        className: `accordion-item, ${opened && 'accordion-item--opened'}`,
      }}
    >
      <div
        // eslint-disable-next-line jsx-a11y/aria-role
        role="input"
        {...{ className: 'accordion-item__line' }}
        onClick={() => {
          setOpened(!opened);
        }}
        onKeyDown={() => {
          setOpened(!opened);
        }}
      >
        <div>
          <img {...{ className: 'accordion-item__groupIcon' }} alt="" src={groupIcon} />
          <h3 {...{ className: 'accordion-item__title' }}>{title}</h3>
        </div>
        <div>
          <span {...{ className: 'accordion-item__vacancies' }}>{`${vacancies} vacancies`}</span>
          <span {...{ className: 'accordion-item__icon' }} />
        </div>
      </div>

      <div {...{ className: 'accordion-item__inner' }}>
        <div {...{ className: 'accordion-item__content' }}>
          {jobs.map((data) => (
            <div
              onClick={(e) => {
                e.preventDefault();
                history(`/job/${encodeURIComponent(data.title)}/${data.board_code}`);
              }}
              {...{ className: 'accordion-item__job-item' }}
            >
              <span {...{ className: 'accordion-item__job-title' }}>{data.title}</span>
              <div className="accordion-item__location-type">
                <span {...{ className: 'accordion-item__location' }}>
                  {`${data.city}, ${data.country_id}`}
                </span>
                <span {...{ className: 'accordion-item__work-type' }}>{data.type}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

AccordionItem.propTypes = {
  title: PropTypes.string,
  groupIcon: PropTypes.string,
  vacancies: PropTypes.number,
  jobs: PropTypes.array,
};

AccordionItem.defaultProps = { title: '', groupIcon: '', vacancies: 0, jobs: [] };

export default AccordionItem;
