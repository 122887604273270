/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import logo from '../../Assets/Images/LOGO-WHITE.svg';

const LoadingScreen = (props) => {
  const { isLoading } = props;
  let container;
  let image;
  if (isLoading) {
    container = 'loading-screen';
    image = 'loading-screen__content__img slide-up slide-up--loaded';
  } else {
    container = 'loading-screen loaded';
    image = 'loading-screen__content__img  slide-up slide-up--loaded slide-up--up';
  }
  return (
    <>
      <div className={container}>
        <div className="loading-screen__content">
          <div className="">
            <div><span className="counter" id="counter">100</span></div>
          </div>
          <div className={image}>
            <div>
              <span>
                <img src={logo} alt="" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

LoadingScreen.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default LoadingScreen;
