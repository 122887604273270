/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { Link } from 'react-router-dom';
import mapMarker from '../Assets/Images/map-marker.svg';
import clock from '../Assets/Images/clock.svg';
import cap from '../Assets/Images/cap.svg';
import administration from '../Assets/Images/administration.svg';
import buying from '../Assets/Images/buying.svg';
import engineering from '../Assets/Images/engineering.svg';
import finance from '../Assets/Images/finance.svg';
import legal from '../Assets/Images/legal.svg';
import peopleCulture from '../Assets/Images/people-culture.svg';
import prCommunication from '../Assets/Images/pr-communication.svg';
import product from '../Assets/Images/product.svg';
import sustainability from '../Assets/Images/sustainability.svg';

const mGroupIcon = new Map();
mGroupIcon.set('Administration', administration);
mGroupIcon.set('Buying', buying);
mGroupIcon.set('Engineering', engineering);
mGroupIcon.set('Finance', finance);
mGroupIcon.set('People & Culture', peopleCulture);
mGroupIcon.set('PR & Communications', prCommunication);
mGroupIcon.set('Product Management', product);
mGroupIcon.set('Legal', legal);
mGroupIcon.set('Sustainability', sustainability);

export default function JobHeader(props) {
  const [css] = useStyletron();
  const headerH1 = css({
    fontFamily: 'Barlow',
    fontSize: '36px',
    lineHeight: '43px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    marginTop: '0',
    textTransform: 'uppercase',
    '@media screen and (max-width: 599px)': {
      fontSize: '28px',
      lineHeight: '34px',
      marginBottom: '10px',
    },
  });

  const headerText = css({
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
    fontSize: '16px',
    lineHeight: '20px',
    marginTop: '0',
    color: '#6D6D6D',
    padding: '0 25px 0px 0px',
    verticalAlign: 'top',
  });

  const headerLink = css({
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
    fontSize: '16px',
    lineHeight: '19px',
    textDecoration: 'none',
    color: '#1771B5',
  });

  const icon = css({
    width: '1.2rem',
    height: '1.2rem',
    margin: '0 8px 0 0 ',
  });

  const jobItem = css({
    display: 'inline-block',
    '@media screen and (max-width: 599px)': {
      display: 'block',
      paddingTop: '5px',
    },
  });

  const container = css({
    background: '#DEDEDE',
    padding: '40px 0',
    '@media screen and (max-width: 599px)': {
      padding: '15px 0 20px 0',
    },
  });

  const { job } = props;

  return (
    <div className={container}>
      <Grid>
        <Cell span={[4, 5, 9]}>
          <h1 className={headerH1}>{job.title}</h1>
          <div>
            <div className={jobItem}>
              <img className={icon} alt="" src={mapMarker} />
              <span className={headerText}>{`${job.city}, ${job.country_id}`}</span>
            </div>
            <div className={jobItem}>
              <img className={icon} alt="" src={clock} />
              <span className={headerText}>{job.type}</span>
            </div>
            <div className={jobItem}>
              <img className={icon} alt="" placeholder={cap} src={mGroupIcon.get(job.department)} />
              <span className={headerText}>{job.department}</span>
            </div>
            <div className={jobItem}>
              <img className={icon} alt="" src={cap} />
              <span className={headerText}>Experience</span>
            </div>
          </div>
        </Cell>
        <Cell span={[4, 3, 3]}>
          <div
            className={css({
              '@media screen and (max-width: 599px)': {
                display: 'none',
              },
            })}
          >
            <div
              className={css({
                textAlign: 'right',
                display: 'inline',
                float: 'left',
              })}
            >
              <Link className={headerLink} to="/">
                {'< '}
                View All Jobs
              </Link>
            </div>
            <div
              className={css({
                textAlign: 'left',
                display: 'inline',
                float: 'right',
              })}
            >
              <a className={headerLink} href="https://global-fashion-group.com">
                View Our Website
                {' >'}
              </a>
            </div>
          </div>
        </Cell>
      </Grid>
    </div>
  );
}
