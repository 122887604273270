/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import './style.scss';

const DrawerIcon = () => (
  <>
    <div
      {...{ className: 'menu-wrap__open' }}
      onClick={() => {
        const menuContainer = document.getElementsByClassName('menu-main-menu-container');
        menuContainer[0].classList.toggle('menu-open');

        const inner = document.getElementsByClassName('hamburger__inner');
        inner[0].classList.toggle('open-menu');
      }}
    >
      <div {...{ className: 'menu__open__icon' }}>
        <div {...{ className: 'hamburger' }}>
          <div {...{ className: 'hamburger__inner' }} />
        </div>
      </div>
    </div>
    <div {...{ className: 'top-nav top-nav--black' }}>
      <div {...{ className: 'menu-main-menu-container' }}>
        <ul id="menu-main-menu" className="menu">
          <li id="menu-item-43" className="menu-item"><a href="https://global-fashion-group.com/about/">About</a></li>
          <li id="menu-item-39" className="menu-item"><a href="https://global-fashion-group.com/markets/">Markets</a></li>
          <li id="menu-item-40" className="menu-item"><a href="https://global-fashion-group.com/brands/">Brands</a></li>
          <li id="menu-item-41" className="menu-item"><a href="https://global-fashion-group.com/people/">People</a></li>
          <li id="menu-item-3758" className="menu-item"><a href="https://global-fashion-group.com/sustainability/">Sustainability</a></li>
          <li id="menu-item-3000" className="menu-item"><a href="https://ir.global-fashion-group.com">Investor Relations</a></li>
          <li id="menu-item-44" className="menu-item"><a href="https://global-fashion-group.com/news/">News</a></li>
          <li id="menu-item-45" className="menu-item"><a href="https://global-fashion-group.com/contact/">Contact</a></li>
        </ul>
      </div>
    </div>
  </>
);

export default DrawerIcon;
