/* eslint-disable operator-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import LoadingScreen from '../Components/LoadingScreen/index';
import TopNavigation from '../Containers/TopNavigation';
import Header from '../Containers/Header';
import JobFilter from '../Containers/JobFilter';
import JobList from '../Containers/JobList';
import Footer from '../Containers/Footer';
import AppContext from '../AppContext';

export default function HomePage() {
  const [searchParams, setSearchParams] = useSearchParams({});

  const jobFilter = {
    location: searchParams.get('location') || '',
    search: searchParams.get('search') || '',
    type: searchParams.get('type') || '',
  };

  const onChangeJobFilter = React.useCallback(
    (data) => {
      setSearchParams({ ...jobFilter, ...data });
    },
    [jobFilter, setSearchParams],
  );

  return (
    <AppContext.Consumer>
      {({ isLoading, jobList, types }) => (
        <>
          <LoadingScreen isLoading={isLoading} />
          <TopNavigation />
          <Header />
          <JobFilter types={types} jobFilter={jobFilter} onJobFilterChange={onChangeJobFilter} />
          <JobList jobList={jobList} jobFilter={jobFilter} isLoading={isLoading} />
          <Footer />
        </>
      )}
    </AppContext.Consumer>
  );
}
